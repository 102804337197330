<template>
  <div class="m1">
    <div class="m1-h">
      <h2><a href="javascript:;" @click="back()"><i class="el-icon-arrow-left"></i></a>  用户详情</h2>
      <div class="m1-tab">
        
      </div>
    </div>
    <el-tabs v-model="activeName">
      <el-tab-pane label="基础信息" name="first">
        <h3>基本信息</h3>
        <ul class="m1-ul">
          <li><label for="">用户ID</label>{{data.key_id}}</li>
          <li><label for="">用户昵称</label><el-input v-model="data.nick_name" placeholder="请输入内容" style="width:184px"></el-input></li>
          <li><label for="">手机号</label><el-input v-model="data.phone" placeholder="请输入内容" style="width:184px"></el-input></li>
          <li><label for="">积分</label><el-input v-model="data.point" placeholder="请输入内容" style="width:184px"></el-input></li>
        </ul>
        <ul class="m1-ul">
          <li><label for="">关联公众号</label><span v-if="data.is_official == 0">否</span><span v-else>是</span></li>
          <li><label for="">注册时间</label>{{data.create_time}}</li>
          <li><label for="">最近登录时间</label>{{data.last_login_time}}</li>
          <li><label for="">最近登录系统</label>{{data.last_device}}</li>
        </ul>
        <h3>异常状态</h3>
        <label for="">异常状态</label>
        <el-radio-group v-model="data.push_status">
          <el-radio :label="0">正常</el-radio>
          <el-radio :label="3">异常</el-radio>
          <el-date-picker
            style="margin-right: 60px"
            v-model="data.lock_time"
            type="datetime"
            placeholder="选择锁定时间">
          </el-date-picker>
          <el-radio :label="1">封禁</el-radio>
        </el-radio-group>
        <h3>推送状态</h3>
        <el-radio-group v-model="data.status">
          <el-radio :label="0">关闭</el-radio>
          <el-radio :label="1">打开</el-radio>
          <el-select v-model="data.push_job" multiple :multiple-limit="5" placeholder="请选择" style="margin-right:14px">
            <el-option v-for="item in sub" :label="item.name" :value="item.id"></el-option>
          </el-select>
          <el-select @change="data.push_city_id = '';changeCity(data.push_province_id)" v-model="data.push_province_id" placeholder="请选择省份" style="margin-right:14px">
            <el-option v-for="item in areaLists" :label="item.label" :value="item.value"></el-option>
          </el-select>
          <el-select v-model="data.push_city_id" placeholder="请选择城市">
            <el-option v-for="item in citys" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-radio-group>
        <div style="margin-top:100px;text-align:center"><el-button type="primary" @click="toSave()">保存</el-button></div>
        
      </el-tab-pane>
      <el-tab-pane label="积分信息" name="second">
        <div class="m1-r1" style="margin-bottom:50px;">
          <div>
            <label for="">积分余额</label>
            <el-input v-model="data.point" placeholder="请输入内容" style="width:184px;margin-right:10px"></el-input>
            <el-button type="primary" @click="toPointSave()">修改</el-button>
          </div>
          <div>

            <el-date-picker
              v-model="pointDates"
              type="daterange"
              style="margin-right: 10px"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
            </el-date-picker>
            <el-select v-model="pointForm.type" style="width:169px; margin-right:10px" placeholder="请选择类型">
              <el-option label="全部" value=""></el-option>
              <el-option label="充值" value="100"></el-option>
              <el-option label="赠送" value="200"></el-option>
              <el-option label="管理端调整" value="201"></el-option>
              <el-option label="拨打电话" value="300"></el-option>
              <el-option label="订阅" value="301"></el-option>
            </el-select>
            <el-button type="primary" @click="getPointList()">查询</el-button>
            <el-button @click="reset()">重置</el-button>
          </div>
        </div>
        <el-table
          border
          :data="pointListData.data"
          style="width: 100%">
          <el-table-column
            prop="create_time"
            label="时间"
            width="180">
          </el-table-column>
          <el-table-column
            prop="type_name"
            label="项目"
            width="180">
          </el-table-column>
          <el-table-column
            prop="point"
            label="积分变化">
          </el-table-column>
          <el-table-column
            prop="curr_point"
            label="积分余额">
          </el-table-column>
          <el-table-column
            prop="amount"
            label="充值金额rmb">
          </el-table-column>
          
        </el-table>
        <div class="m1-r1">
          <div>共<span class="m1-text1">{{pointListData.total}}</span>条记录</div>
          <div>
            <el-pagination
              background
              layout="prev, pager, next"
              @current-change="getPointList()"
              :current-page.sync="form.page"
              :total="pointListData.total">
            </el-pagination>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="用户简历" name="third">
        <h3>基本信息</h3>
        <ul class="m1-ul">
          <li><label for="">姓名</label><el-input v-model="resume.name" placeholder="请输入内容" style="width:184px"></el-input></li>
          <li><label for="">电话</label><el-input v-model="resume.tel" placeholder="请输入内容" style="width:184px"></el-input></li>
          <li>
            <label for="">工作地点</label>
            <el-select @change="resume.city_id = ''; changeCity2(resume.province_id)" v-model="resume.province_id" placeholder="请选择省份" style="width:100px;margin-right:14px">
              <el-option v-for="item in areaLists" :label="item.label" :value="item.value"></el-option>
            </el-select>
            <el-select v-model="resume.city_id" placeholder="请选择城市" style="width:100px;">
              <el-option v-for="item in citys2" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </li>
          <li>
            <label for="">工种</label>
            <el-select v-model="resume.job_type" multiple :multiple-limit="5" placeholder="请选择" style="margin-right:14px">
              <el-option v-for="item in sub" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </li>
        </ul>
        <ul class="m1-ul">
          <li>
            <label for="">人员构成</label>
            <el-select v-model="resume.staff_type" placeholder="请选择">
              <el-option label="个人" :value="1"></el-option>
              <el-option label="施工队" :value="2"></el-option>
            </el-select>
            <el-input :disabled="resume.staff_type != 2" v-model="resume.staff_num" style="width:50px; margin: 0 10px"></el-input> 人
          </li>
          <li><label for="">期望薪资 元/月</label><el-input v-model="resume.salary" placeholder="请输入内容" style="width:184px"></el-input></li>
          <li>
            <label for="">结算方式</label>
            <el-select v-model="resume.settle_type" placeholder="请选择">
              <el-option label="日结" :value="1"></el-option>
              <el-option label="月结" :value="2"></el-option>
              <el-option label="计量" :value="3"></el-option>
              <el-option label="面议" :value="4"></el-option>
            </el-select>
          </li>
        </ul>
        <h3>求职状态</h3>
        <el-radio-group v-model="resume.submit_status">
          <el-radio :label="0">未提交</el-radio>
          <el-radio :label="1">已提交</el-radio>
        </el-radio-group>
        <div style="margin-top:100px;text-align:center"><el-button type="primary" @click="toResumeSave()">保存</el-button></div>
      </el-tab-pane>
      <el-tab-pane label="招聘信息拨打记录" name="fourth">
        <div style="text-align:right; margin-bottom:24px">
          <el-date-picker
            v-model="dates"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
          <el-button type="primary" @click="getList()" style="margin-left:10px">查询</el-button>
          <el-button @click="resetPhone()" style="margin-left:10px">重置</el-button>
        </div>
        <el-table
          border
          :data="calldata.data"
          style="width: 100%">
          <el-table-column
            prop="create_time"
            label="拨打时间"
            width="180">
          </el-table-column>
          <el-table-column
            prop="phone"
            label="电话"
            width="180">
          </el-table-column>
          <el-table-column
            prop="province_name"
            label="工作地点">
          </el-table-column>
          <el-table-column
            prop="point"
            label="标签">
            <template slot-scope="scope">
              {{scope.row.type_name}} {{scope.row.sub_type_name}}
            </template>
          </el-table-column>
          <el-table-column
            prop="content"
            label="正文">
          </el-table-column>
          <el-table-column
            prop="lock_time"
            label="信息状态">
            <template slot-scope="scope">
              <span v-if="scope.row.status == 0">未找到</span>
              <span v-else>未找到</span>
            </template>
          </el-table-column>
          
        </el-table>
        <div class="m1-r1">
          <div>共<span class="m1-text1">{{calldata.total}}</span>条记录</div>
          <div>
            <el-pagination
              background
              layout="prev, pager, next"
              @current-change="getList()"
              :current-page.sync="form.page"
              :total="calldata.total">
            </el-pagination>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="求职信息拨打记录" name="fifth">
        <div style="text-align:right; margin-bottom:24px">
          <el-date-picker
            v-model="datesResume"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
          <el-button type="primary" @click="getResumeList()" style="margin-left:10px">查询</el-button>
          <el-button @click="resetResume()" style="margin-left:10px">重置</el-button>
        </div>
        <el-table
          border
          :data="callResumeData.data"
          style="width: 100%">
          <el-table-column
            prop="create_time"
            label="拨打时间"
            width="180">
          </el-table-column>
          <el-table-column
            prop="phone"
            label="电话"
            width="180">
          </el-table-column>
          <el-table-column
            prop="province_name"
            label="工作地点">
          </el-table-column>
          <el-table-column
            prop="point"
            label="标签">
            <template slot-scope="scope">
              {{scope.row.type_name}} {{scope.row.sub_type_name}}
            </template>
          </el-table-column>
          <el-table-column
            prop="content"
            label="正文">
          </el-table-column>
          <el-table-column
            prop="lock_time"
            label="信息状态">
            <template slot-scope="scope">
              <span v-if="scope.row.status == 0">未找到</span>
              <span v-else>未找到</span>
            </template>
          </el-table-column>
          
        </el-table>
        <div class="m1-r1">
          <div>共<span class="m1-text1">{{callResumeData.total}}</span>条记录</div>
          <div>
            <el-pagination
              background
              layout="prev, pager, next"
              @current-change="getList()"
              :current-page.sync="formResume.page"
              :total="callResumeData.total">
            </el-pagination>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import {
  detail, save, calllist, resumeDetail, resumeSave, pointSave, pointList, areaLists, sub, callResumeList
} from '@/api/user';
import moment from 'moment'
import { setTimeout } from 'timers';
export default {
  name: 'index',
  data() {
    return {
      activeName: 'first',
      data: {
      },
      calldata: {
        data: []
      },
      callResumeData: {
        data: []
      },
      dates: [],
      datesResume: [],
      pointDates: [],
      form: {
        user_id: this.$route.query.id,
        begin_date: '',
        end_date: '',
        page: 1,
        page_size: 10
      },
      formResume: {
        user_id: this.$route.query.id,
        begin_date: '',
        end_date: '',
        page: 1,
        page_size: 10
      },
      pointForm: {
        user_id: this.$route.query.id,
        type: '',
        begin_date: '',
        end_date: '',
        page: 1,
        page_size: 10
      },
      pointListData: {},
      resume: {},
      areaLists: [],
      citys: [],
      citys2: [],
      sub: []
    }
  },
  computed: {
  },
  created() {
    
  },
  mounted(){
    this.getAreaLists()
    this.getList()
    this.getPointList()
    this.getSub()
    this.getResumeList()
  },
  methods: {
    getDetail () {
      detail({
        id: this.$route.query.id
      }).then(res =>{
        this.data = res.data.data
        this.changeCity(this.data.push_province_id)
        if (this.data.lock_time == null) {
          this.data.lock_time = ''
        } else {
          this.data.lock_time = moment(this.data.lock_time)
        }
        console.log(this.data)
      })
    },
    toSave () {
      save({
        id: this.$route.query.id,
        nick_name: this.data.nick_name,
        phone: this.data.phone,
        point: this.data.point,
        status: this.data.status,
        lock_time: this.data.lock_time,
        push_city_id: this.data.push_city_id,
        push_province_id: this.data.push_province_id,
        push_status: this.data.push_status,
        push_job: this.data.push_job,
      }).then(res =>{
        if (res.data.msg == 'ok') {
          this.$message({
            type: 'success',
            message: '保存成功'
          });
        } else {
          this.$message({
            type: 'error',
            message: res.data.msg
          });   
        }
      })
    },
    getList () {
      this.form.begin_date = this.dates.length > 0 ? moment(this.dates[0]).format('YYYY-MM-DD') : '';
      this.form.end_date = this.dates.length > 0 ? moment(this.dates[1]).format('YYYY-MM-DD') : '';
      calllist(this.form).then(res =>{
        if (res.data.data) {
          this.calldata = res.data.data
        }
        
      })
    },
    getResumeList () {
      this.formResume.begin_date = this.dates.length > 0 ? moment(this.dates[0]).format('YYYY-MM-DD') : '';
      this.formResume.end_date = this.dates.length > 0 ? moment(this.dates[1]).format('YYYY-MM-DD') : '';
      callResumeList(this.formResume).then(res =>{
        if (res.data.data) {
          this.callResumeData = res.data.data
        }
        
      })
    },
    getResumeDetail () {
      resumeDetail({
        user_id: this.$route.query.id
      }).then(res =>{
        this.resume = res.data.data
        this.changeCity2(this.resume.province_id)
        
      })
    },
    toResumeSave () {
      resumeSave({
        user_id: this.$route.query.id,
        name: this.resume.name,
        tel: this.resume.tel,
        is_insurance: this.resume.is_insurance,
        years: this.resume.years,
        work_range: this.resume.work_range,
        salary: this.resume.salary,
        is_night: this.resume.is_night,
        settle_type: this.resume.settle_type,
        staff_type: this.resume.staff_type,
        staff_num: this.resume.staff_num,
        submit_status: this.resume.submit_status,
        city_id: this.resume.city_id,
        province_id: this.resume.province_id,
        job_type: this.resume.job_type,
      }).then(res =>{
        if (res.data.msg == 'ok') {
          this.$message({
            type: 'success',
            message: '保存成功'
          });
        } else {
          this.$message({
            type: 'error',
            message: res.data.msg
          });   
        }
      })
    },
    toPointSave () {
      this.$confirm('是否确认修改', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        pointSave({
          user_id: this.$route.query.id,
          point: this.data.point
        }).then(res =>{
          if (res.data.msg == 'ok') {
            this.$message({
              type: 'success',
              message: '保存成功'
            });
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            });   
          }
        })
      }).catch(() => {
                  
      });
    },
    getPointList () {
      this.pointForm.begin_date = this.pointDates.length > 0 ? moment(this.pointDates[0]).format('YYYY-MM-DD') : '';
      this.pointForm.end_date = this.pointDates.length > 0 ? moment(this.pointDates[1]).format('YYYY-MM-DD') : '';
      pointList(this.pointForm).then(res =>{
        this.pointListData = res.data.data
      })
    },
    getAreaLists () {
      areaLists().then(res =>{
        this.areaLists = res.data.data
        this.getDetail()
        this.getResumeDetail()
      })
    },
    getSub () {
      sub().then(res =>{
        this.sub = res.data.data
      })
    },
    changeCity (id) {
      this.areaLists.forEach((item) => {
        if (item.value == id) {
          this.citys = item.children
        } 
      })
    },
    changeCity2 (id) {
      this.areaLists.forEach((item) => {
        if (item.value == id) {
          this.citys2 = item.children
        } 
      })
    },
    reset () {
      this.pointDates = []
      this.pointForm.begin_date = '';
      this.pointForm.end_date = '';
      this.pointForm.type = ''
    },
    resetPhone() {
      this.dates = []
      this.form.begin_date = ''
      this.form.end_date = ''
    },
    resetResume() {
      this.datesResume = []
      this.formResume.begin_date = ''
      this.formResume.end_date = ''
    },
    back() {
      history.back()
    }
  },
}
</script>
<style scoped lang="scss">
.m1-h{
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}
.m1-r1{
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.m1-text1{
  color: #409EFF;
}
.m1-ul{
  display: flex;
  margin-bottom: 39px;
}
.m1-ul>li{
  width: 25%;
  list-style: none;
}
.m1 label{
  width: 7em;
  display: inline-block;
}
.m1 h3{
  margin: 28px 0;
}
</style>
